import React from 'react';
import FormComponent from '../components/FormComponent';

function Contact() {
  return (
    <div>
      <FormComponent />
    </div>
  );
}

export default Contact;
